import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Modal({ children, onClose, heading }) {
    return <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10 flex justify-center items-center">
        <div className="relative rounded-lg bg-white box-shadow h-auto p-8 pt-0">
            <div className="absolute mt-3 top-0 right-0 cursor-pointer" onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} className="text-2xl mr-4 text-brand" />
            </div>
            {heading ? <div className="text-xl text-brand mt-6 font-bold">
                {heading}
            </div> : null }
            {children}
        </div>
    </div>;
}

export default Modal;