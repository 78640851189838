import './Landing.scss';
import logo from '../../assets/images/COPALogo.svg';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../../authConfig";

function Landing() {
  let navigate = useNavigate();
  const { instance } = useMsal();

  // useEffect(() => {
  //   const account = msalInstance.getActiveAccount();
    
  //   if (!account) {
  //       return;
  //   }

  //   msalInstance.acquireTokenSilent({
  //       ...loginRequest,
  //       account: account
  //   })
  //   .then(response => {
  //     if(response.accessToken) {
  //       navigate('/dashboard');
  //     }
  //   })
  //   .catch(() => {});
  // })

  const login = () => {
    //if (loginType === "popup") {
      //instance.loginPopup(loginRequest);
    // } else if (loginType === "redirect") {
    instance.loginPopup(loginRequest)
    .then(response => {
      if(response != null) {
        instance.setActiveAccount(response.account);
        navigate('/dashboard');
      }
    })
    .catch(() => {

    });
    // }
  }

  return (
    <div className="App landing">
      <img src={logo} width={350} height="auto"/>
      <h1 className='font-bold text-3xl mt-4'>Admin Dashboard</h1>
      <div onClick={login} className='btn font-bold bg-white text-brand rounded py-3 px-8 mt-8 cursor-pointer'>Log In</div>
    </div>
  );
}

export default Landing;