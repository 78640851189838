import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

function OutboundLinks({ route }) {
    const [links, setLinks] = useState(null);
    const [error, setError] = useState(null);
    const [linkOverrides, setLinkOverrides] = useState({});

    useEffect(() => {
        loadLinks();
    }, []);

    const loadLinks = () => {
        APIFetch('GET', `outboundlink`)
        .then(response => {
            if(response.ok) {
                var formattedData = {
                    Default: []
                };

                // Group by category
                    // Group by subcategory
                response.data.forEach(l => {
                    if(!l.category) {
                        formattedData.Default.push(l);
                    } else if(!l.subCategory) {
                        if(!formattedData[l.category]) {
                            formattedData[l.category] = { Default: [l] };
                        } else {
                            formattedData[l.category].Default.push(l);
                        }
                    } else {
                        if(!formattedData[l.category]) {
                            formattedData[l.category] = { Default: [] };
                        }

                        if(!formattedData[l.category][l.subCategory]) {
                            formattedData[l.category][l.subCategory] = [l];
                        } else {
                            formattedData[l.category][l.subCategory].push(l);
                        }
                    }
                });

                setLinks(formattedData);
            } else {
                setError(true);
            }
        })
        .catch(() => {
            setError(true);
        });
    }

    const overrideLink = (id, value) => {
        var newOverrides = { ...linkOverrides };
        newOverrides[id] = value;
        setLinkOverrides(newOverrides);
    }

    const saveLink = (l) => {
        APIFetch('POST', `outboundlink/${l.id}`, linkOverrides[l.id])
        .then(response => {
            if(response.ok) {
                loadLinks();
            } else {
                setError(true);
            }
        })
        .catch(() => {
            setError(true);
        });
    }

    const renderList = () => {
        var output = null;

        if(!links || Object.keys(links).length === 0) return null;

        // Go through each top level category
        output = Object.keys(links).sort((a,b) => {
            if (a === 'Default')
                return 1;
            if (b === 'Default')
                return -1;
            return a.localeCompare(b);
        }).map(key => {
            var categoryOutputs = [];

            // Go through each subcategory
            Object.keys(links[key]).sort((a,b) => {
                if (a === 'Default')
                    return 1;
                if (b === 'Default')
                    return -1;
                return a.localeCompare(b);
            }).map(subKey => {
                var subArray = links[key][subKey];
                var subItems = subArray.sort((a,b) => {
                    return a.name.localeCompare(b.name)
                }).map(l => {
                    return <div className="flex flex-col pl-8 pt-2 basis-1/2">
                        <div className="flex flex-row items-center">
                            <div className="flex flex-shrink-0 items-center font-bold">{l.name}</div>
                            {linkOverrides[l.id] && linkOverrides[l.id] !== l.link ? <FontAwesomeIcon icon={faSave} className="cursor-pointer ml-2 text-black text-xl" onClick={() => { saveLink(l) }}/> : null}
                        </div>
                        <input type="text" className="text-black border border-brand-grey flex-grow my-2 p-2 px-4 w-full rounded-full" value={linkOverrides[l.id] || l.link} onChange={(e) => { overrideLink(l.id, e.target.value) }}/>
                    </div>
                });

                categoryOutputs.push(<div className="flex flex-row flex-wrap">
                    {subKey === 'Default' ? null : <div className="font-bold text-lg ml-4 w-full my-4">{subKey}</div>}
                    {subItems}
                </div>)
            });

            return [
                key === 'Default' ? null : <div className="font-bold text-xl mb-2 mt-6">{key}</div>,
                ...categoryOutputs
            ];
        });

        return output;
    }

    return (
        <div className="App">
            {error !== null ? <Modal onClose={() => setError(null)} heading={"Something went wrong"}>
                <div className="text-black">An error occurred while making your request.</div>
                <div className="flex flex-row mt-2">
                    <div onClick={() => { setError(null); }} className='cursor-pointer font-bold bg-brand text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>OK</div>
                </div>
            </Modal> : null}
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="flex flex-col border-white border p-2 m-3 bg-white rounded mb-4 w-full">
                    <div className="flex flex-row items-center text-brand font-bold pl-3 pr-3 pt-2 pb-2">
                        <div className="text-2xl">Outbound Links</div>
                    </div>
                    <div className="px-4 overflow-y-scroll text-brand">
                        {renderList()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OutboundLinks;