import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import FeedList from "../../components/FeedList";
import Sidebar from "../../components/Sidebar/Sidebar";
import SearchHeader from "../../components/SearchHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import APIFetch from "../../utilities/APIFetch";

function UserSummary() {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [details, setDetails] = useState(null);
    const [busy, setBusy] = useState(false);
    const [banModal, setBanModal] = useState(false);
    const [banMessage, setBanMessage] = useState("");

    useEffect(() => {
        APIFetch('GET', `user/${userId}`)
        .then(response => {
          if(response.ok) {
            setDetails(response.data);
          }
        })
    }, [userId]);

    const toggleBan = () => {
        if(!busy) {
            setBusy(true);

            APIFetch('POST', `tools/${details.user.banned ? 'unban' : 'ban'}${details.user.banned ? "/" + details.user.id : ''}`, details.user.banned ? null : {
                userId: details.user.id,
                reason: banMessage || ""
            })
            .then(response => {
                if(response.ok) {
                    var newDetails = {...details};
                    newDetails.user = {...newDetails.user};
                    newDetails.user.banned = !newDetails.user.banned;
                    setDetails(newDetails);
                    setBanModal(false);
                }
                setBusy(false);
            })
            .catch(e => {
                // TODO:
                setBusy(false);
            })
        }
    }

    return <div className="flex flex-row w-full h-full">
        <Sidebar />
        <div className="flex flex-col p-4 flex-grow">
            <SearchHeader />
            <div className="text-brand mx-4 mt-8 mb-2 cursor-pointer" onClick={() => { navigate(-1) }}>
                <FontAwesomeIcon icon={faChevronLeft} className="mr-2"/>
                <span>{"Back to submission details"}</span>
            </div>
            <div className="text-3xl mb-8 mx-4 font-bold text-brand">{ details ? details.user.forename + " " + details.user.surname + " - User Summary" : "User Summary"}</div>
            {banModal ? <Modal onClose={() => setBanModal(false)} heading={details.user.banned ? " Are you sure you wish to remove the ban on this user?" : "Are you sure you wish to ban this user?"}>
                <div className="text-black my-6">{details.user.banned ? "The user will regain access to the COPA app." : "The user will no longer be able to access the COPA app."}</div>
                {details.user.banned ? null : <div className="text-brand font-bold">Optional: Reason for the ban (visible to the user):</div> }
                {details.user.banned ? null : <textarea className="text-black border border-black rounded-xl flex-grow my-2 p-1 w-full min-w-500" type="text" value={banMessage} onChange={(e) => { setBanMessage(e.target.value) }}/> }
                <div className="flex flex-row mt-4 mb-2">
                    <div onClick={() => { setBanModal(false); }} className='cursor-pointer bg-gray-400 text-white rounded-xl text-center py-2 ml-auto mr-auto mt-auto w-36'>Cancel</div>
                    <div onClick={() => { toggleBan(); }} className='cursor-pointer bg-brand text-white rounded-xl text-center py-2 ml-auto mr-auto mt-auto w-36'>{details.user.banned ? "Unban" : "Ban"}</div>
                </div>
            </Modal> : null}
            <div className="flex flex-row px-4">
                <div className="flex flex-col">
                    { details ? <div className="flex flex-col bg-white p-6 mb-4 rounded-lg text-black shadow min-w-800">
                        <div className="text-lg text-brand font-bold mb-2">User Details{details.user.banned ? " (BANNED USER)" : (details.user.deleted ? " (DELETED USER)" : "")} <FontAwesomeIcon icon={faUser} className="text-xl text-brand ml-2 cursor-pointer"/></div>
                        <div className="flex flex-row justify-between items-center">
                            <div className="flex flex-row my-1 mr-6">
                                <div className="font-bold mr-2 text-brand">Name:</div>
                                <div>{details.user.forename} {details.user.surname}</div>
                            </div>
                            <div className="flex flex-row my-1 mr-6">
                                <div className="font-bold mr-2 text-brand">Email:</div>
                                <div>{details.user.email || '-'}</div>
                            </div>
                            <div className="flex flex-row my-1 mr-6">
                                <div className="font-bold mr-2 text-brand">Phone Number:</div>
                                <div>{details.user.phoneNumber}</div>
                            </div>
                            <div className="flex flex-row my-1">
                                <div onClick={() => { setBanModal(true) }} className='cursor-pointer bg-brand text-white rounded-xl py-2 px-8 h-min ml-auto mr-auto mt-auto'>{details.user.banned ? "Unban User" : "Ban User"}</div>
                            </div>
                        </div>
                    </div> : null }
                </div>
            </div>
            { details ? <div className="flex flex-col px-4 flex-grow overflow-y-hidden">
                <FeedList userId={details.user.id}  searchProps={{ placeholder: "Search user..." }}/>
            </div> : null }
        </div>
    </div>;
}

export default UserSummary;