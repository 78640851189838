import Map, { Marker } from 'react-map-gl';
import { useState, useEffect } from 'react';
import appConfig from '../../appConfig.json';
import MediaGallery from '../../components/MediaGallery';
import APIFetch from '../../utilities/APIFetch';

// Fix for worker tranpilation error
import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const HATE_INCIDENT_TYPES = [
    "Disability",
    "Ethnicity",
    "Gender",
    "Religion",
    "Sexual Orientation"
]

function ReportDetails({ report }) {
    const [mediaLinks, setMediaLinks] = useState(null);
    const [showMedia, setShowMedia] = useState(false);

    useEffect(() => {
        function onKeyup(e) {
          if (e.key === 'Escape' && showMedia) {
              setShowMedia(false);
          }
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
    }, [showMedia]);

    const onDelete = (idx) => {
        setMediaLinks(mediaLinks.filter((value, index) => index !== idx));
    }

    const openMedia = () => {
        if(mediaLinks === null) {
            APIFetch('GET', `media/${report.id}`)
            .then(response => {
                if(response.ok) {
                    setMediaLinks(response.data);
                    if(response.data && response.data.length > 0) {
                        setShowMedia(true);
                    }
                } else if(response.status === 404) {
                    setMediaLinks([]);
                    // TODO:
                } else {
                    // TODO:
                }
          });
        } else {
            if(mediaLinks && mediaLinks.length > 0) {
                setShowMedia(true);
            }
        }
    }

    const additionalFields = (r) => {
        if(report.typeName === "Vehicle - Speeding" || report.typeName === "Vehicle - ASB") {
            var reg = report.additionalFields && report.additionalFields.length > 0 ? report.additionalFields.find(f => f.field === "vehicle_reg") : null;
            return <div className="flex flex-col basis-1/4">
                <div className="font-bold mr-2 mb-1 text-brand">Vehicle Registration:</div>
                <div>{reg ? reg.value || "Not provided" : "Not provided"}</div>
            </div>
        } else if(report.typeName === "Hate Incident") {
            var incidentType = report.additionalFields && report.additionalFields.length > 0 ? report.additionalFields.find(f => f.field === "hate_incident_type") : null;
            return <div className="flex flex-col basis-1/4">
                <div className="font-bold mr-2 mb-1 text-brand">Hate Incident Type:</div>
                <div>{incidentType && incidentType.value >= 0 ? HATE_INCIDENT_TYPES[incidentType.value] : "Not provided"}</div>
            </div>
        }
    }

    return <div className="flex flex-row bg-white p-6 mb-4 rounded-lg text-black shadow">
        {showMedia ? <MediaGallery links={mediaLinks} reportId={report.id} onDelete={onDelete} onClose={() => {setShowMedia(false)}}/> : null }
        <div className="flex flex-col flex-grow">
            <div className="text-lg text-brand font-bold mb-4">Report Details</div>
            <div className="flex flex-row my-1 mb-6 flex-grow">
                <div className="flex flex-col basis-1/4">
                    <div className="font-bold mr-2 mb-1 text-brand">Report Type:</div>
                    <div>{report.typeName}</div>
                </div>
                <div className="flex flex-col basis-1/4">
                    <div className="font-bold mr-2 mb-1 text-brand">Reference Number:</div>
                    <div>{report.referenceNumber ? report.referenceNumber.toString().padStart(8, '0') : ''}</div>
                </div>
                <div className="flex flex-col basis-1/4">
                    <div className="font-bold mr-2 mb-1 text-brand">Submitted On:</div>
                    <div>{new Date(report.createdAt).toLocaleDateString('en-GB')} {new Date(report.createdAt).toLocaleTimeString('en-GB')}</div>
                </div>
                <div className="flex flex-col basis-1/4">
                    <div className="font-bold mr-2 mb-1 text-brand">Date of Incident:</div>
                    { report.incidentTime && report.incidentTime !== '0001-01-01T00:00:00' ? <div>{new Date(report.incidentTime).toLocaleDateString('en-GB')} {new Date(report.incidentTime).toLocaleTimeString('en-GB')}</div> : '-'}
                </div>
            </div>
            <div className="flex flex-col my-1 mb-6">
                <div className="font-bold mr-2 mb-1 text-brand">Description:</div>
                <div>{report.description}</div>
            </div>
            <div className="flex flex-row my-1 mb-6 flex-grow">
                <div className="flex flex-col basis-1/4">
                    <div className="font-bold mr-2 mb-1 text-brand">Incident Number:</div>
                    <div>{report.incidentNumber || "Not provided"}</div>
                </div>
                {additionalFields()}
            </div>
            <div className='flex flex-row flex-grow gap-4'>
                {report.locationX && report.locationY ? <Map
                    initialViewState={{
                        longitude: report.locationX,
                        latitude: report.locationY,
                        zoom: 12
                    }}
                    style={{width: 400, height: 200, borderRadius: 20, borderWidth: 1, borderColor: 'black', marginRight: 10 }}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                    mapboxAccessToken={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV].MAPBOX_TOKEN}>
                    <Marker
                        longitude={report.locationX}
                        latitude={report.locationY}
                    />
                </Map> : null }
                <div className="flex flex-col my-1">
                    <div className="font-bold mb-1 text-brand">Address of Incident:</div>
                    {report.address ? <div>
                        <div>{report.address.buildingNumber} {report.address.dependentStreet || report.address.street}</div>
                        {report.address.dependentStreet ? <div>{report.address.street}</div> : null}
                        <div>{report.address.town}</div>
                        <div>{report.address.postcode}</div>
                    </div>
                    : (report.location ? report.location.split(",").map(l => <div>{l}</div>) : <div>Not provided</div>)}
                </div>
                {(!mediaLinks || mediaLinks.length > 0) && report.mediaCount > 0 ? <div className="flex flex-col my-1 ml-auto mt-auto">
                    <div onClick={() => { openMedia(); }} className='flex items-center cursor-pointer bg-brand text-white rounded-xl py-2 px-8 mr-auto'>{`View Attached Media (${mediaLinks !== null && mediaLinks.length ? mediaLinks.length : report.mediaCount})`}</div>
                    {report.compressionTime > 0 ? <div className="text-center">Files took {report.compressionTime}s to compress</div> : null }
                </div> : null }
            </div>
        </div>
    </div>;
}

export default ReportDetails;