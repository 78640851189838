import {
  Routes,
  Route,
  useNavigate,
  Router
} from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import NavigateSetter from "./components/NavigateSetter";
import Dashboard from "./pages/Dashboard/Dashboard";
import Landing from './pages/Landing/Landing';
import Reporting from "./pages/Reporting/Reporting";
import Outcomes from "./pages/Outcomes/Outcomes";
import SubmissionDetails from "./pages/SubmissionDetails/SubmissionDetails";
import UserSummary from "./pages/UserSummary/UserSummary";
import { CustomNavigationClient } from "./utilities/CustomNavigationClient";
import OutboundLinks from "./pages/OutboundLinks/OutboundLinks";
import Exports from "./pages/Exports/Exports";

function App({ pca }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return <MsalProvider instance={pca}>
    <NavigateSetter />
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/reporting/:reportName" element={<Reporting />} />
      <Route path="/exports" element={<Exports />} />
      <Route path="/user/:userId" element={<UserSummary />} />
      <Route path="/submission/:type/:id" element={<SubmissionDetails />} />
      <Route path="/settings" element={<Outcomes />} />
      <Route path="/settings/outcomes" element={<Outcomes />} />
      <Route path="/settings/links" element={<OutboundLinks />} />
    </Routes>
  </MsalProvider>
}

export default App;
