import { msalInstance } from "../index";
import appConfig from "../appConfig.json";
import { loginRequest } from "../authConfig";
import History from "./History";

const APIFetch = async (method, url, requestBody, multipartBody) => {
    const account = msalInstance.getActiveAccount();
    
    if (!account) {
        History.navigate('/');
        return Promise.reject({ ok: false, status: null, data: null });
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    var token = response.accessToken;

    if(!token) {
        History.navigate('/');
        return Promise.reject({ ok: false, status: null, data: null });
    }

    var headers = {
        'Authorization': 'Bearer ' + token
    };

    if(requestBody) {
        headers['Content-Type'] = 'application/json';
    }

    return fetch(appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_DOMAIN + url, {
        method: method,
        mode: 'cors',
        headers: headers,
        body: multipartBody ? multipartBody : (requestBody ? JSON.stringify(requestBody) : null)
    })
    .then((response) => {
        if(response.ok) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json()
                .then(result => {
                    return { ok: response.ok, status: response.status, data: result };
                })
                .catch(e => {
                    return e;
                });
            } else if (contentType && contentType.indexOf("text/plain") !== -1) {
                return response.text()
                .then(result => {
                    return { ok: response.ok, status: response.status, data: result };
                })
                .catch(e => {
                    return e;
                });
            } else {
                return { ok: response.ok, status: response.status, data: null };
            }
        } else {
            if(response.status === 403 || response.status === 401) {
                History.navigate('/');
                return { ok: false, status: null, data: null };
            } else if(response.status === 400) {
                return response.text()
                .then(result => {
                    return { ok: response.ok, status: response.status, data: result };
                })
                .catch(e => {
                    return { ok: response.ok, status: response.status, data: response };
                });
            } else {
                return { ok: response.ok, status: response.status, data: response };
            }
        }
    })
    .catch((e) => {
        return e;
    })
}

export default APIFetch;