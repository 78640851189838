import { ResponsiveCalendar } from '@nivo/calendar';
import { useEffect, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import APIFetch from '../../utilities/APIFetch';

function ReportsByDay() {
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        // Go back to start of first day
        var fromDate = new Date();
        fromDate.setMonth(0);
        fromDate.setDate(1);
        fromDate.setHours(0, 0, 0, 0);
        setFrom(fromDate);

        // Go back to start of day
        var toDate = new Date();
        toDate.setHours(0, 0, 0, 0);
        setTo(toDate);

        APIFetch('POST', `reporting/reportsbyday`, {
            from: fromDate,
            to: toDate
        })
        .then(response => {
            if(response.ok) {
                var formatted = [];

                response.data.forEach(r => {
                    formatted.push({
                        day: r.day.split("T")[0],
                        value: r.value
                    })
                });
                
                setData(formatted);
            }
        })
    }, []);

    const theme = {
        textColor: '#2623FF',
        fontSize: '12px',
        background:'rgb(146, 177, 251, 0.2)',
        grid: {
            line: {
                stroke: '#2623FF'
            }
        },
        axis: {
            ticks: {
                text: {
                    fontWeight: 'bold'
                }
            }
        },
        legends: {
            text: {
                fontWeight: 'bold'
            }
        },
        tooltip: {
            container: {
                color: '#000000'
            }
        }
    };

    return (
        <div className="flex flex-col p-4 flex-grow h-full">
            <div className="text-3xl mt-4 mb-8 font-bold text-brand">Reports Per Day Calendar</div>
            { data ? <ResponsiveCalendar
                data={data}
                theme={theme}
                from={from}
                to={to}
                emptyColor="#eeeeee"
                colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                yearSpacing={40}
                monthBorderColor="#ffffff"
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        translateY: -225,
                        itemCount: 4,
                        itemWidth: 42,
                        itemHeight: 36,
                        itemsSpacing: 14
                    }
                ]}
            /> : <div className="w-full flex flex-row justify-center items-center mb-6 mt-2">
                <SpinnerCircular size={50} color="#003399" secondaryColor="white" />
            </div> }
        </div>
    );
}

export default ReportsByDay;