const FEEDBACK_TYPES = [
    "Complaint",
    "Thanks",
    "Suggestion"
]

function FeedbackDetails({ feedback }) {
    return <div className="flex flex-col bg-white p-4 mb-4 rounded-lg text-black shadow">
        <div className="text-lg text-brand font-bold">Feedback Details</div>
        <div className="flex flex-row my-1">
            <div className="font-bold mr-2">Reference Number:</div>
            <div>{feedback.referenceNumber ? feedback.referenceNumber.toString().padStart(8, '0') : ''}</div>
        </div>
        <div className="flex flex-row my-1">
            <div className="font-bold mr-2">Feedback Type:</div>
            <div>{FEEDBACK_TYPES[feedback.type]}</div>
        </div>
        <div className="flex flex-row my-1">
            <div className="font-bold mr-2">Submitted On:</div>
            <div>{new Date(feedback.createdAt).toLocaleDateString('en-GB')} {new Date(feedback.createdAt).toLocaleTimeString('en-GB')}</div>
        </div>
        <div className="flex flex-col my-1">
            <div className="font-bold mr-2">Description:</div>
            <div>{feedback.description}</div>
        </div>
    </div>;
}

export default FeedbackDetails;