import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { useState } from "react";
import { SpinnerCircular } from "spinners-react";
import Modal from "../../components/Modal";
import Sidebar from "../../components/Sidebar/Sidebar";
import APIFetch from "../../utilities/APIFetch";

const today = new Date();
const tomorrow = new Date(today);

tomorrow.setDate(tomorrow.getDate() + 1);

function Exports() {
    const [error, setError] = useState(null);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(format(tomorrow, "yyyy-MM-dd"));
    const [waiting, setWaiting] = useState(false);
    let hasDates = from && to && to > from;

    const downloadControlRoom = () => {
        if(hasDates && !waiting) {
            setWaiting(true);
            APIFetch('POST', 'export/controlroom', { from: from, to: to })
            .then((result) => {
                if(result.ok) {
                    // Download
                    var dateString = ("0" + today.getDate()).slice(-2) + ("0" + (today.getMonth() + 1)).slice(-2) + today.getFullYear();

                    var blob = new Blob([result.data]);
                    var url = URL.createObjectURL(blob);
                    var downloadLink = document.createElement("a");
                    downloadLink.href = url;
                    downloadLink.download = `Control Room Data - ${dateString}.csv`;

                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                } else {
                    setError(true);
                }
            })
            .catch(() => { setError(true) })
            .finally(() => setWaiting(false));
        }
    }

    return (
        <div className="App">
            {error !== null ? <Modal onClose={() => setError(null)} heading={"Something went wrong"}>
                <div className="text-black">An error occurred while making your request.</div>
                <div className="flex flex-row mt-2">
                    <div onClick={() => { setError(null); }} className='cursor-pointer font-bold bg-brand text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>OK</div>
                </div>
            </Modal> : null}
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="flex flex-col border-white border p-2 m-3 bg-white rounded mb-4 w-full">
                    <div className="flex flex-row items-center text-brand font-bold pl-3 pr-3 pt-2 pb-2">
                        <div className="text-2xl">Data Exports</div>
                    </div>
                    <div className="px-4 overflow-y-scroll text-brand pt-4 flex flex-col">
                        <div className="flex flex-row">
                            <div>
                                <div className="font-bold mb-1">From</div>
                                <input className="border border-brand p-1 rounded-xl py-2 px-3" value={from} type="date" onChange={e => setFrom(e.target.value)}/>
                            </div>
                            <div className="ml-5">
                                <div className="font-bold mb-1">To</div>
                                <input className="border border-brand p-1 rounded-xl py-2 px-3" value={to} type="date" onChange={e => setTo(e.target.value)}/>
                            </div>
                        </div>
                        <div className="text-xl font-bold mt-8">Available Reports</div>
                        <div onClick={downloadControlRoom} className={"flex items-center bg-white rounded-xl py-2 h-min px-4 mr-auto mt-3 border" + (!hasDates ? " text-brand-grey border-brand-grey cursor-not-allowed" : " border-brand text-brand cursor-pointer")}>Control Room Data <FontAwesomeIcon className="ml-4" icon={faDownload} /></div>
                    </div>
                </div>
                {waiting ? <div className="absolute w-full h-full flex flex-row justify-center items-center bg-brand-grey-transparent">
                    <SpinnerCircular size={50} color="#003399" secondaryColor="white" />
                </div> : null }
            </div>
        </div>
    );
}

export default Exports;