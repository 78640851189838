import { ResponsivePie } from '@nivo/pie';
import { useEffect, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import appConfig from '../../appConfig.json';
import APIFetch from '../../utilities/APIFetch';

const theme = {
    textColor: '#2623FF',
    fontSize: '12px',
    background:'rgb(146, 177, 251, 0.2)',
    grid: {
        line: {
            stroke: '#2623FF'
        }
    },
    axis: {
        ticks: {
            text: {
                fontWeight: 'bold'
            }
        }
    },
    legends: {
        text: {
            fontWeight: 'bold'
        }
    },
    tooltip: {
        container: {
            color: '#000000'
        }
    }
};

function ReportsByOutcome() {
    const [data, setData] = useState(null);

    useEffect(() => {
        APIFetch('GET', `reporting/countbyoutcome`)
        .then(response => {
          if(response.ok) {
            setData(response.data);
          }
        })
    }, []);

    return (
        <div className="flex flex-col p-4 flex-grow">
            <div className="text-3xl mt-4 mb-8 font-bold text-brand">Total Reports By Outcome</div>
            { data ? <ResponsivePie
                data={data}
                theme={theme}
                colors={{ scheme: 'category10' }}
                margin={{ top: 40, right: 150, bottom: 40, left: 0 }}
                innerRadius={0.4}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{ from: 'color' }}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsTextColor={{ from: 'color' }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsThickness={2}
                arcLabelsSkipAngle={10}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        translateX: -100,
                        itemWidth: 0,
                        itemHeight: 30,
                        symbolSize: 12,
                        symbolShape: 'circle'
                    }
                ]}
            /> : <div className="w-full flex flex-row justify-center items-center mb-6 mt-2">
                <SpinnerCircular size={50} color="#003399" secondaryColor="white" />
            </div> }
        </div>
    );
}

export default ReportsByOutcome;