import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import appConfig from '../../appConfig.json';
import FeedbackDetails from "./FeedbackDetails";
import ReportDetails from "./ReportDetails";
import QuestionDetails from "./QuestionDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faEdit, faSave, faTrashAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import SearchHeader from "../../components/SearchHeader";
import { SpinnerCircular } from "spinners-react";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";
import NoteEntry from '../../components/NoteEntry';
import { useMsal } from "@azure/msal-react";   

const REPORT_STATES = [
    { value: 1, text: "Submitted" },
    { value: 2, text: "Pending Review" },
    { value: 3, text: "Pending Feedback" },
    { value: 100, text: "Complete" },
    { value: 101, text: "Rejected" },
];

const FEEDBACK_STATES = REPORT_STATES;
const QUESTION_STATES = REPORT_STATES;

function SubmissionDetails() {
    const { type, id } = useParams();
    let navigate = useNavigate();
    const [details, setDetails] = useState(null);

    const [busy, setBusy] = useState(false);
    const [submittingUpdate, setSubmittingUpdate] = useState(false);
    const [submittingOutcome, setSubmittingOutcome] = useState(false);

    const [newState, setNewState] = useState(0);
    const [newOutcome, setNewOutcome] = useState(0)
    const [updateMessage, setUpdateMessage] = useState('');

    const [outcomes, setOutcomes] = useState(null);

    const [canUpdate, setCanUpdate] = useState(false);

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const { instance } = useMsal();
    const myId = instance.getActiveAccount().localAccountId;

    useEffect(() => {
        console.log(details);
        if(details) {
            if(details.stateId != 100 && details.stateId != 101) {
                setCanUpdate(true);
            }
        }
    }, [details, type]);

    useEffect(() => {
        APIFetch('GET', `feed/${type}/${id}`)
        .then(response => {
          if(response.ok) {
              setDetails(response.data);
              setNewState(response.data.stateId || 1);
              setNewOutcome(response.data.outcomeId || 1);

              APIFetch('GET', `outcome`)
              .then(response2 => {
                if(response2.ok) {
                    setOutcomes(response2.data.filter(r => { return (!r.inactive || r.id === response.data.outcomeId) }));
                }
              })
            }
        });
    }, [id, type]);

    const refreshData = () => {
        // Update notes on change etc
        APIFetch('GET', `feed/${type}/${id}`)
        .then(response => {
          if(response.ok) {
              setDetails(response.data);
              setNewState(response.data.stateId || 1);
              setNewOutcome(response.data.outcomeId || 1);
            }
        });
    }

    const sendToForceControl = () => {
        if(!busy) {
            APIFetch('POST', `submission/sendtoforcecontrol/${details.id}`)
            .then(response => {
                if(response.ok) {
                    setSuccess(true);
                } else {
                    if(response.data && response.data.length > 0) {
                        setError(response.data);
                    } else {
                        setError(true);
                    }
                }

                setBusy(false);
                refreshData();
            })
            .catch(e => {
                setError(true);
                setBusy(false);
            })
        }
    }

    const submitUpdate = () => {
        if(!busy) {
            setBusy(true);
            setSubmittingUpdate(true);

            APIFetch('POST', `feed/update`, {
                type: parseInt(type),
                id: id,
                stateId: parseInt(newState),
                message: updateMessage
            })
            .then(response => {
                if(response.ok) {
                    setSuccess(true);
                    setUpdateMessage("");
                    refreshData();
                } else {
                    if(response.data && response.data.length > 0) {
                        setError(response.data);
                    } else {
                        setError(true);
                    }
                }

                setBusy(false);
                setSubmittingUpdate(false);
            })
            .catch(e => {
                setError(true);
                setBusy(false);
                setSubmittingUpdate(false);
            })
        }
    }

    const submitOutcome = () => {
        if(!busy) {
            setBusy(true);
            setSubmittingOutcome(true);

            APIFetch('POST', `submission/assignoutcome/${id}/${newOutcome}`)
            .then(response => {
                if(response.ok) {
                    setSuccess(true);
                    refreshData();
                } else {
                    setError(true);
                }

                setBusy(false);
                setSubmittingOutcome(false);
            })
            .catch(e => {
                setError(true);
                setBusy(false);
                setSubmittingOutcome(false);
            })
        }
    }

    const createNote = () => {
        var clone = {...details};
        clone.notes = [...clone.notes];
        clone.notes.push({ tempId: Math.random(), id: null, userId: myId, text: "", createdAt: Date.now(), editing: true });
        setDetails(clone);
    }

    const updateNote = (idx, value) => {
        var clone = {...details};
        clone.notes = [...clone.notes];
        clone.notes[idx] = {...clone.notes[idx]};
        clone.notes[idx].text = value;
        setDetails(clone);
    }

    const saveNote = (idx) => {
        if(!busy) {
            setBusy(true);

            APIFetch('POST', `note/${type}/${details.id}`, { text: details.notes[idx].text })
            .then(response => {
                if(response.ok) {
                    setSuccess(true);
                    var clone = {...details};
                    clone.notes = [...clone.notes];
                    clone.notes[idx] = {...clone.notes[idx]};
                    clone.notes[idx].editing = false;
                    setDetails(clone);
                } else {
                    setError(true);
                }

                setBusy(false);
            })
            .catch(e => {
                setError(true);
                setBusy(false);
            })
        }
    }

    const deleteNote = (idx) => {
        APIFetch('DELETE', `note/${type}/${details.notes[idx].id}`)
        .then(response => {
            if(response.ok) {
                var clone = {...details};
                clone.notes = [...clone.notes];
                clone.notes.splice(idx, 1);
                setDetails(clone);
                setSuccess(true);
            }
        });
    }

    let options = [];

    switch(parseInt(type)) {
        case 0:
            options = REPORT_STATES.map(s => <option value={s.value}>{s.text}</option>);
            break;
        case 1:
            options = QUESTION_STATES.map(s => <option value={s.value}>{s.text}</option>);
            break;
        case 2:
            options = FEEDBACK_STATES.map(s => <option value={s.value}>{s.text}</option>);
            break;
        default: break;
    }

    return <div className="flex flex-row w-full h-full overflow-y-hidden">
        <Sidebar />
        <div className="flex flex-col p-4 flex-grow">
            {error ? <Modal onClose={() => setError(false)} heading={"Something went wrong"}>
                <div className="text-black mt-2 mb-8">{error.length > 0 ? error : "An error occurred while making your request."}</div>
                <div className="flex flex-row mt-2">
                    <div onClick={() => { setError(false); }} className='cursor-pointer font-bold bg-brand text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>OK</div>
                </div>
            </Modal> : null}
            {success ? <Modal onClose={() => setSuccess(false)} heading={"Success"}>
                <div className="text-black mt-2 mb-8">Your change was saved successfully.</div>
                <div className="flex flex-row mt-2">
                    <div onClick={() => { setSuccess(false); }} className='cursor-pointer font-bold bg-brand text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>OK</div>
                </div>
            </Modal> : null}
            <SearchHeader />
            <div className="text-brand mx-4 mt-8 mb-2 cursor-pointer" onClick={() => { navigate(-1) }}>
                <FontAwesomeIcon icon={faChevronLeft} className="mr-2"/>
                <span>{"Back to all submissions"}</span>
            </div>
            <div className="flex flex-row justify-between align-center mb-8 mx-4">
                <div className="text-3xl font-bold text-brand">Submission Details</div>
                {type == 0 ? <div onClick={sendToForceControl} className='cursor-pointer bg-brand text-white rounded-xl h-min py-2 px-8 text-center flex justify-center items-center' style={{ minHeight: 40 }}>{details && details.forwardedOn ? 'Resend to Force Control' : 'Send to Force Control'}</div> : null}
            </div>
            {details ? <div className="flex flex-col p-4 overflow-y-auto">
                <div className="flex flex-row">
                    <div className={"flex flex-col mr-2" + (canUpdate ? " basis-8/12" : " basis-full")}>
                        {type == 0 ? <ReportDetails report={details} /> : null}
                        {type == 1 ? <QuestionDetails question={details} /> : null}
                        {type == 2 ? <FeedbackDetails feedback={details} /> : null}
                        <div className="flex flex-col bg-white p-6 mb-4 rounded-lg text-black shadow">
                            <div className="flex flex-row items-center mb-4">
                                <div className="text-lg text-brand font-bold">User Details{details.user.banned ? " (BANNED USER)" : (details.user.deleted ? " (DELETED USER)" : "")}</div>
                                <FontAwesomeIcon icon={faUser} className="text-xl text-brand ml-4 cursor-pointer" color={"white"} onClick={() => { navigate(`/user/${details.user.id}`)}}/>
                            </div>
                            <div className="flex flex-row flex-grow my-1 ">
                                <div className="flex flex-col basis-1/3">
                                    <div className="flex flex-row mb-2">
                                        <div className="font-bold mr-2 text-brand">Name:</div>
                                        <div>{details.user.forename} {details.user.surname}</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="font-bold mr-2 text-brand">Email:</div>
                                        <div>{details.user.email}</div>
                                    </div>
                                </div>
                                
                                <div className="flex flex-row basis-1/3">
                                    <div className="font-bold mr-2 text-brand">Phone Number:</div>
                                    <div>{details.user.phoneNumber}</div>
                                </div>
                                <div className="flex flex-row basis-1/3">
                                    <div onClick={() => { navigate(`/user/${details.user.id}`)  }} className='flex w-40 justify-center items-center cursor-pointer bg-brand text-white rounded-xl py-2 h-min px-8 ml-auto'>View</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col rounded-lg basis-4/12 ml-2 align-center">
                        {canUpdate ? <div className="flex flex-grow flex-col text-black align-center bg-white p-6 mb-4 rounded-lg shadow">
                            <div className="font-bold text-brand text-lg mb-4">Update</div>
                            <div className="flex flex-row items-center mb-2">
                                <div className="font-bold mr-2 text-brand">New State:</div>
                                <select className="text-black my-2" value={newState} onChange={(e) => { setNewState(e.target.value) }}>
                                    {options}
                                </select>
                            </div>
                            <div className="font-bold mr-2 text-brand mb-2">Message for user:</div>
                            <textarea className="text-black border border-black rounded-xl flex-grow my-2 p-1" type="text" value={updateMessage} onChange={(e) => { setUpdateMessage(e.target.value) }}/>
                            <div onClick={submitUpdate} className='cursor-pointer bg-brand text-white rounded-xl h-min py-2 px-8 ml-auto mr-auto mt-2 w-40 text-center flex justify-center items-center' style={{ minHeight: 40 }}>{submittingUpdate ? <SpinnerCircular size={25} color="#003399" secondaryColor="white" /> : "Submit"}</div>
                        </div> : null }
                        {parseInt(type) === 0 ? <div className="flex flex-col bg-white p-6 mb-4 text-black align-center rounded-lg shadow">
                            <div className="font-bold text-brand text-lg mb-2">Outcome</div>
                            <div className="flex flex-row items-center">
                                <div className="font-bold mr-2 text-brand">Outcome:</div>
                                <select className="text-black my-2" value={newOutcome} onChange={(e) => { setNewOutcome(e.target.value) }}>
                                    {outcomes && outcomes.length ? outcomes.map(o => {
                                        return <option value={o.id}>{o.name}</option>;
                                    }) : null }
                                </select>
                            </div>
                            <div onClick={submitOutcome} className='cursor-pointer bg-brand text-white rounded-xl h-min py-2 px-8 ml-auto mr-auto mt-2 w-40 text-center flex justify-center items-center' style={{ minHeight: 40 }}>{submittingOutcome ? <SpinnerCircular size={25} color="#003399" secondaryColor="white" /> : "Submit"}</div>
                        </div> : null }
                    </div>
                </div>
                <div className="flex flex-grow flex-col text-black align-center bg-white p-6 mb-4 rounded-lg shadow">
                    <div className="flex flex-row items-center mb-4">
                        <div className="text-lg text-brand font-bold">Notes</div>
                        <FontAwesomeIcon icon={faEdit} className="text-xl text-brand ml-4 cursor-pointer" color={"white"} onClick={() => { createNote() }}/>
                    </div>
                    <div className="grid grid-cols-[max-content_max-content_1fr_max-content]">
                        <div className="px-4 py-2 text-brand font-bold">Date</div>
                        <div className="px-4 py-2 text-brand font-bold">Admin</div>
                        <div className="px-4 py-2 text-brand font-bold">Note</div>
                        <div className="px-4 py-2 text-brand font-bold">Actions</div>
                        {details.notes.map((n, i) => <NoteEntry note={n} idx={i} saveNote={saveNote} updateNote={updateNote} deleteNote={deleteNote}/>).reverse()}
                    </div>
                </div>
            </div>
            : <div className="w-full flex flex-row justify-center items-center mb-6 mt-2">
            <SpinnerCircular enabled={!details} size={50} color="#003399" secondaryColor="white" />
        </div> }
        </div>
    </div>;
}

export default SubmissionDetails;