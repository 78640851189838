import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/COPALogo.svg';
import { AuthStore } from '../../stores/AuthStore';
import SidebarItem from "./SidebarItem";

const ITEMS = [
  {
    title: "All Submissions",
    uri: '/dashboard'
  },
  {
    title: "Reporting",
    uri: '/reporting/home',
    subItems: [
      {
        title: "Quick Stats",
        uri: '/reporting/home'
      },
      {
        title: "Total Reports By Type",
        uri: '/reporting/reportsbytype'
      },
      {
        title: "Total Reports By Type Within Each Ward",
        uri: '/reporting/reportsbytypebyward'
      },
      {
        title: "Report Breakdown",
        uri: '/reporting/reportsovertime'
      },
      {
        title: "Reports Per Day Calendar",
        uri: '/reporting/reportsbyday'
      },
      {
        title: "Average Response Times",
        uri: '/reporting/responsetimes'
      },
      {
        title: "Outbound Links This Month",
        uri: '/reporting/outboundlinksthismonth'
      },
      {
        title: "Total Reports By Users' Ward",
        uri: '/reporting/reportsbyward'
      },
      {
        title: "Total Reports By Occurrance Ward",
        uri: '/reporting/reportsinward'
      },
      {
        title: "Total Reports By District",
        uri: '/reporting/reportsbydistrict'
      },
      {
        title: "Total Reports By Outcome",
        uri: '/reporting/reportsbyoutcome'
      }
    ]
  },
  {
    title: "Data Exports",
    uri: '/exports',
  },
  {
    title: "Settings",
    uri: '/settings/links',
    subItems: [
      {
        title: "Outbound Links",
        uri: '/settings/links'
      },
      {
        title: "Outcomes",
        uri: '/settings/outcomes'
      }
    ]
  }
]

function Sidebar() {
  const navigate = useNavigate();

  const logOut = () => {
    AuthStore.update(s => { s.accessToken = null });
    navigate('/');
  }

  return (
    <div className="flex flex-col flex-shrink-0 w-56 p-2 bg-brand">
        <img src={logo} width={125} className="mt-8 ml-4"/>
        <div className="flex flex-col flex-1 overflow-y-auto">
          <div className="flex flex-col flex-grow mt-16">
            {ITEMS.map((item, i) => <SidebarItem {...item} key={i} />)}
          </div>
          <div className="p-4 cursor-pointer mt-auto" onClick={logOut}>
            <span className='mr-2'>Log Out</span><FontAwesomeIcon icon={faArrowRightFromBracket} />
          </div>
        </div>
    </div>
  );
}

export default Sidebar;