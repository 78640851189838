import { ResponsiveLine } from '@nivo/line';
import { useEffect, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import APIFetch from '../../utilities/APIFetch';

const theme = {
    textColor: '#2623FF',
    fontSize: '12px',
    background:'rgb(146, 177, 251, 0.2)',
    grid: {
        line: {
            stroke: '#2623FF'
        }
    },
    axis: {
        ticks: {
            text: {
                fontWeight: 'bold'
            }
        }
    },
    legends: {
        text: {
            fontWeight: 'bold'
        }
    },
    tooltip: {
        container: {
            color: '#000000'
        }
    }
};

function ResponseTimes() {
    const [data, setData] = useState(null);

    useEffect(() => {
        APIFetch('GET', `reporting/responsetimes`)
        .then(response => {
          if(response.ok) {
            var formattedData = [
                {
                    id: 'Avg Response Times (days)',
                    data: []
                }
            ];

            response.data.forEach((d) => {
                formattedData[0].data.push({
                    x: d.day,
                    y: d.value
                })
            });

            setData(formattedData);
          }
        })
    }, []);

    return (
        <div className="flex flex-col p-4 flex-grow h-full">
            <div className="text-3xl mt-4 mb-8 font-bold text-brand">Average Response Times</div>
            { data ? <ResponsiveLine
                data={data}
                theme={theme}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                colors={[ '#ff0000' ]}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: '0',
                    max: 'auto',
                    stacked: true,
                    reverse: false
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Day',
                    legendOffset: 36,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Avg Response Time (days)',
                    legendOffset: -40,
                    legendPosition: 'middle'
                }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                tooltipFormat={a => 'Cheese3'}
            /> : <div className="w-full flex flex-row justify-center items-center mb-6 mt-2">
                <SpinnerCircular size={50} color="#003399" secondaryColor="white" />
            </div> }
        </div>
    );
}

export default ResponseTimes;