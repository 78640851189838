import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'nuka-carousel';
import './MediaGallery.scss';
import { useState } from 'react';
import Modal from './Modal';
import APIFetch from '../utilities/APIFetch';

function MediaGallery({ reportId, links, onClose, onDelete }) {
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentIdx, setCurrentIdx] = useState(0);

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const deleteMedia = () => {
        APIFetch('DELETE', `media/${reportId}/${links[currentIdx].id}`)
        .then(response => {
            if(response.ok) {
                onDelete(currentIdx);

                if(currentIdx > 0) {
                    setCurrentIdx(currentIdx - 1);
                    setSuccess(true);
                }

                if(links.length == 1) {
                    onClose();
                }
            } else {
                setError(true);
            }

            setDeleteModal(false);
        })
        .catch(() => {
            setError(true);
            setDeleteModal(false);
        })
    }

    return <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10">
        {deleteModal ? <Modal onClose={() => setDeleteModal(false)} heading={`Delete Item?`}>
                <div className="text-black mt-4">Are you sure you wish to delete this media item?</div>
                <div className="text-black mb-8">This item will be completely deleted from the system and cannot be restored.</div>
                <div className="flex flex-row mt-2">
                    <div onClick={() => { setDeleteModal(false); }} className='cursor-pointer font-bold bg-gray-400 text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>Cancel</div>
                    <div onClick={() => { deleteMedia(null); }} className='cursor-pointer font-bold bg-red-600 text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>Delete</div>
                </div>
            </Modal> : null}
        {error ? <Modal onClose={() => setError(false)} heading={"Something went wrong"}>
            <div className="text-black mt-2 mb-8">An error occurred when attempting to delete this item.</div>
            <div className="flex flex-row mt-2">
                <div onClick={() => { setError(false); }} className='cursor-pointer font-bold bg-brand text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>OK</div>
            </div>
        </Modal> : null}
        {success ? <Modal onClose={() => setSuccess(false)} heading={"Success"}>
            <div className="text-black mt-2 mb-8">The item was deleted successfully.</div>
            <div className="flex flex-row mt-2">
                <div onClick={() => { setSuccess(false); }} className='cursor-pointer font-bold bg-brand text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>OK</div>
            </div>
        </Modal> : null}
        <Carousel slideIndex={currentIdx} afterSlide={(idx) => { setCurrentIdx(idx); }}>
            {links ? links.map(l => {
                if(l.mediaType === 'Photo') {
                    return <div className="flex justify-center items-center h-screen pb-24" key={l.url}>
                        <img src={l.url} className="max-h-screen"/>
                    </div>
                } else if(l.mediaType === 'Video') {
                    return <div className="flex justify-center items-center h-screen pb-24" key={l.url}>
                        <video controls src={l.url} className="max-h-screen"/>
                    </div>
                }
            }) : null}
        </Carousel>
        <div className="fixed top-0 right-0 cursor-pointer">
            <FontAwesomeIcon icon={faTrash} className="text-2xl mr-5 mt-3" color={"white"} onClick={() => { setDeleteModal(true); }}/>
            <FontAwesomeIcon icon={faTimes} className="text-2xl mr-5 mt-3" color={"white"} onClick={onClose}/>
        </div>
    </div>;
}

export default MediaGallery;