import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import appConfig from '../../appConfig.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/Modal";
import APIFetch from "../../utilities/APIFetch";

function Outcomes({ route }) {
    const [outcomes, setOutcomes] = useState(null);
    const [addModal, setAddModal] = useState(null);
    const [renameModal, setRenameModal] = useState(null);
    const [deleteModal, setDeleteModal] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        APIFetch('GET', `outcome`)
        .then(response => {
            if(response.ok) {
                setOutcomes(response.data.filter(r => r.id !== 1));
            } else {
                setError(true);
            }
        })
        .catch(() => {
            setError(true);
        })
    }, []);

    const deleteOutcome = (id) => {
        APIFetch('DELETE', `outcome/${id}`)
        .then(response => {
            if(response.ok) {
                var newOutcomes = [...outcomes];

                if(response.data === true) {
                    newOutcomes = newOutcomes.filter(o => o.id !== id)
                } else {
                    var editedOutcome = newOutcomes.find(o => o.id === id);
                    editedOutcome.inactive = true;
                }
                
                setOutcomes(newOutcomes);
                setDeleteModal(null);
            } else {
                setError(true);
            }
        })
        .catch(() => {
            setError(true);
        })
    }

    const renameOutcome = () => {
        if(renameModal.originalName === renameModal.newName) {
            setRenameModal(null);
            return;
        }

        APIFetch('POST', `outcome/rename/${renameModal.id}/${renameModal.newName}`)
        .then(response => {
            if(response.ok) {
                var newOutcomes = [...outcomes];
                var editedOutcome = newOutcomes.find(o => o.id === renameModal.id);
                editedOutcome.name = renameModal.newName;
                setOutcomes(newOutcomes);
                setRenameModal(null);
            } else {
                setError(true);
            }
        })
        .catch(() => {
            setError(true);
        })
    }

    const addOutcome = () => {
        if(addModal.length > 0) {
            APIFetch('POST', `outcome/${addModal}`)
            .then(response => {
                if(response.ok) {
                    var newOutcomes = [...outcomes];
                    newOutcomes.push(response.data);
                    setOutcomes(newOutcomes);
                    setAddModal(null);
                }
            })
            .catch(() => {
                setError(true);
            })
        } else {
            setError(true);
        }
    }

    const reactivateOutcome = (id) => {
        APIFetch('POST', `outcome/reactivate/${id}`)
        .then(response => {
            if(response.ok) {
                var newOutcomes = [...outcomes];
                var editedOutcome = newOutcomes.find(o => o.id === id);
                editedOutcome.inactive = false;
                setOutcomes(newOutcomes);
            } else {
                setError(true);
            }
        })
        .catch(() => {
            setError(true);
        })
    }

    return (
        <div className="App">
            {error !== null ? <Modal onClose={() => setError(null)} heading={"Something went wrong"}>
                <div className="text-black">An error occurred while making your request.</div>
                <div className="flex flex-row mt-2">
                    <div onClick={() => { setError(null); }} className='cursor-pointer font-bold bg-brand text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>OK</div>
                </div>
            </Modal> : null}
            {addModal !== null ? <Modal onClose={() => setAddModal(null)} heading={"Create a new outcome option"}>
                <div className="text-black">Name:</div>
                <input type="text" className="text-black border flex-grow my-2 p-1 w-full" value={addModal || ""} onChange={(e) => { setAddModal(e.target.value) }}/>
                <div className="flex flex-row mt-2">
                    <div onClick={() => { setAddModal(null); }} className='cursor-pointer font-bold bg-gray-400 text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>Cancel</div>
                    <div onClick={() => { addOutcome(); }} className='cursor-pointer font-bold bg-green-600 text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>Save</div>
                </div>
            </Modal> : null}
            {renameModal !== null ? <Modal onClose={() => setRenameModal(null)} heading={`Rename ${renameModal.originalName} outcome`}>
                <div className="text-black">Name:</div>
                <input type="text" className="text-black border flex-grow my-2 p-1 w-full" value={renameModal.newName || ""} onChange={(e) => { setRenameModal({ id: renameModal.id, originalName: renameModal.originalName,  newName: e.target.value }) }}/>
                <div className="flex flex-row mt-2">
                    <div onClick={() => { setRenameModal(null); }} className='cursor-pointer font-bold bg-gray-400 text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>Cancel</div>
                    <div onClick={() => { renameOutcome(); }} className='cursor-pointer font-bold bg-green-600 text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>Save</div>
                </div>
            </Modal> : null}
            {deleteModal !== null ? <Modal onClose={() => setDeleteModal(null)} heading={`Delete '${deleteModal.name}'?`}>
                <div className="text-black">Are you sure you wish to delete this outcome?</div>
                <div className="text-black">If this outcome is already in use it will be deactivated rather than deleted.</div>
                <div className="flex flex-row mt-2">
                    <div onClick={() => { setDeleteModal(null); }} className='cursor-pointer font-bold bg-gray-400 text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>Cancel</div>
                    <div onClick={() => { deleteOutcome(deleteModal.id); }} className='cursor-pointer font-bold bg-red-600 text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>Delete</div>
                </div>
            </Modal> : null}
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="basis-1/3 border-white border p-2 m-3 bg-white rounded mb-4">
                    <div className="flex flex-row items-center text-brand font-bold pl-3 pr-3 pt-2 pb-2">
                        <div className="text-xl">Outcomes</div>
                        <FontAwesomeIcon className="ml-auto text-xl mr-1 cursor-pointer" icon={faPlus} onClick={() => setAddModal("")}/>
                    </div>
                    <div className="overflow-y-scroll">
                        {outcomes ? outcomes.map(o => {
                            return <div className={"flex flex-row text-brand p-2 pl-4 pr-4 bg-white rounded m-2 items-center shadow border-brand border" + (o.inactive ? " text-gray-500 border-gray-500" : "")}> 
                                <div className="flex flex-grow">{o.name}</div>
                                { !o.inactive ? <FontAwesomeIcon className="mr-2 cursor-pointer" icon={faEdit} onClick={() => setRenameModal({ id: o.id, originalName: o.name, newName: o.name })} /> : null }
                                { !o.inactive ? <FontAwesomeIcon className="mr-1 cursor-pointer" icon={faTrash} onClick={() => setDeleteModal({ id: o.id, name: o.name })} /> : null }
                                { o.inactive ? <FontAwesomeIcon className="mr-1 cursor-pointer" icon={faPlus} onClick={() => reactivateOutcome(o.id)} /> : null }
                            </div>
                        }) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Outcomes;