import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ToggleOptions from './ToggleOptions';

function SearchHeader({ onSubmit, placeholder, initialValue, initialState, onRefresh }) {
    const [searchValue, setSearchValue] = useState("");
    const [stateFilter, setStateFilter] = useState(0);

    let navigate = useNavigate();

    useEffect(() => {
        setSearchValue(initialValue || "");
        setStateFilter(initialState || 1);
    }, []);

    useEffect(() => {
        setSearchValue(initialValue || "");
        setStateFilter(initialState || 1);
    }, [initialValue]);

    const submit = (stateOverride = null) => {
        if(!onSubmit) {
            navigate(`/dashboard`, { state: { search: searchValue, state: stateOverride !== null ? stateOverride : stateFilter } });
        } else {
            onSubmit({ searchValue, state: stateOverride !== null ? stateOverride : stateFilter });
        }
    };

    const detectEnter = ({key}) => {
        if(key === "Enter") {
            submit();
        }
    }
    
    // TODO: Add new option after feedback/question state changes
    return (
        <div className="relative flex flex-row mb-2 items-center" key={"search"}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute text-brand-grey left-3"/>
            <input type="text" className="text-black border border-brand-grey p-2 pl-9 rounded-full w-96 mr-2" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyUp={detectEnter} placeholder={placeholder ? placeholder : "Search..."}/>
            {onSubmit ? <ToggleOptions options={["All", "Open", "Closed"]} onChange={(idx) => { setStateFilter(idx); submit(idx); }} value={stateFilter} /> : null }
            {onRefresh ? <FontAwesomeIcon icon={faRefresh} onClick={() => { onRefresh() }} className="cursor-pointer ml-auto mr-4 text-brand text-3xl"/> : null}
        </div>
    );
}

export default SearchHeader;