import { faSave, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMsal } from "@azure/msal-react";   
import { StaffStore } from "../stores/StaffStore";
import { useStoreState } from "pullstate";
import { getProfile } from "../utilities/GraphAPI";

const NoteEntry = ({ note, idx, saveNote, updateNote, deleteNote }) => {
    const n = note;
    const i = idx;
    const { instance } = useMsal();
    const myId = instance.getActiveAccount().localAccountId;
    const staff = useStoreState(StaffStore, s => { return s[n.userId] || null });

    if(n.userId && n.userId !== 'TEMPID' && myId !== n.userId && staff === null) {
        getProfile(n.userId)
        .catch(e => {});
    }

    var fromDate = new Date(n.createdAt).toLocaleDateString('en-GB');

    return [
        <div key={(n.id || n.tempId) + "-date"} className={"px-4 py-8" + (i !== 0 ? " border-t" : "")}>{fromDate.substring(0, 6) + fromDate.substring(8)} - {new Date(n.createdAt).toLocaleTimeString().substring(0, 5)}</div>,
        <div key={(n.id || n.tempId) + "-user"} className={"px-4 py-8" + (i !== 0 ? " border-t" : "")}>{myId === n.userId ? 'You' : staff && staff.displayName ? staff.displayName : '-'}</div>,
        !n.editing ? <div key={(n.id || n.tempId) + "-text"} className={"px-4 py-8" + (i !== 0 ? " border-t" : "")}>{n.text}</div> : <input type="text" key={(n.id || n.tempId) + "-textinput"} className={"px-4 py-8" + (i === 0 ? " border-t" : "")} value={n.text} onChange={(e) => { updateNote(i, e.target.value) }}/>,
        <div key={(n.id || n.tempId) + "-actions"} className={"px-4 py-8 text-center" + (i !== 0 ? " border-t" : "")}>
            {n.editing ? <FontAwesomeIcon icon={faSave} className="mr-2 cursor-pointer" onClick={() => saveNote(i)}/> : null }
            <FontAwesomeIcon icon={faTrashAlt} className="cursor-pointer" onClick={() => deleteNote(i)}/>
        </div>
    ];
}

export default NoteEntry;