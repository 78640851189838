import { NavigationClient } from "@azure/msal-browser";

/**
 * react-router-dom v6 implementation of msal NavigationClient
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/3d1cbe7599bbc00da12159a1c0c5e4aa720fb7a6/samples/msal-react-samples/react-router-sample/src/utils/NavigationClient.js#L6
 */
 export class CustomNavigationClient extends NavigationClient {  
    constructor(navigate) {
        super();
        this.navigate = navigate;
    }

    async navigateInternal(url, options) {
      const relativePath = url.replace(window.location.origin, '');
      if (options.noHistory) {
        this.navigate(relativePath, { replace: true });
      } else {
        this.navigate(relativePath);
      }
  
      return false;
    }
}