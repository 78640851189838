import { useNavigate } from "react-router-dom";

const TYPES = {
    0: "Report",
    1: "Question",
    2: "Feedback"
}

function FeedItem({ item }) {
    const f = item;
    let navigate = useNavigate();

    const onClick = () => {
        navigate(`/submission/${f.type}/${f.id}`);
    }

    return <div onClick={onClick} className="flex flex-row bg-white p-4 m-2 rounded-xl text-brand shadow cursor-pointer">
        <div className="flex flex-col items-center justify-center text-center basis-2/12">
            <div className="">{f.referenceNumber ? f.referenceNumber.toString().padStart(8, '0') : ''}</div>
        </div>
        <div className="flex flex-col items-center justify-center text-center basis-3/12">
            <div className="">{TYPES[f.type]} {f.reportType ? " - " + f.reportType : null }</div>
        </div>
        <div className="flex flex-col items-center justify-center text-center basis-2/12">
            <div className={"" + (f.userDeleted ? " line-through text-gray-600" : (f.userBanned ? " line-through text-red-600" : ""))}>{f.firstName} {f.surname}</div>
        </div>
        <div className="flex flex-col items-center justify-center text-center basis-2/12">
            <div className="">{new Date(f.createdAt).toLocaleDateString('en-GB')}</div>
        </div>
        <div className="flex flex-col items-center justify-center text-center basis-2/12">
            <div>{f.state}</div>
        </div>
    </div>;
}

export default FeedItem;