import { ResponsiveBar } from '@nivo/bar';
import { useEffect, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import APIFetch from '../../utilities/APIFetch';

function ReportsByTypeByWard() {
    const [types, setTypes] = useState([]);
    const [data, setData] = useState(null);
    const [dataLen, setDataLen] = useState(0);

    useEffect(() => {
        APIFetch('GET', `reporting/reportsbytypebyward`)
        .then(response => {
            if(response.ok) {
                var processedData = [];
                response.data.forEach(r => {
                    var newEntry = {
                        "ward": r.ward,
                        "Anti Social Behaviour": r.asb,
                        "Suspicious Behaviour": r.suspiciousBehaviour,
                        "Drugs": r.drugs,
                        "Hate Incident": r.hateIncident,
                        "Vehicle - Speeding": r.vehicleSpeeding,
                        "Vehicle - Anti Social Behaviour": r.vehicleASB,
                        "value": r.value
                    };

                    processedData.push(newEntry);
                });
                response.data = processedData;
                let types = [];
                response.data.forEach(group => {
                    types = [...new Set([...types, ...Object.keys(group).filter(key => {
                        return (key !== 'ward') && (key !== 'value');
                    })])];
                })

                const testArr = [];
                for (let i = 0; i < 100; i++) {
                    testArr.push({
                        'Anti Social Behaviour': Math.floor(Math.random() * 11),
                        'Vehicle - Anti Social Behaviour': Math.floor(Math.random() * 11),
                        'Drugs': Math.floor(Math.random() * 11),
                        'Hate Incident': Math.floor(Math.random() * 11),
                        'Suspicious Behaviour': Math.floor(Math.random() * 11),
                        'Vehicle - Speeding': Math.floor(Math.random() * 11),
                         ward: "Stockton & Hardwick " + i
                    })
                }

                // setDataLen(testArr.length);
                setDataLen(response.data.length);

                // setData(testArr);
                setData(response.data);
                setTypes(types);
            }
        })
    }, []);

    const theme = {
        textColor: '#2623FF',
        fontSize: '12px',
        background:'rgb(146, 177, 251, 0.2)',
        grid: {
            line: {
                stroke: '#2623FF'
            }
        },
        axis: {
            ticks: {
                text: {
                    fontWeight: 'bold'
                }
            }
        },
        legends: {
            text: {
                fontWeight: 'bold'
            }
        },
        tooltip: {
            container: {
                color: '#000000'
            }
        }
    };

    return (
        <div className="flex flex-col p-4 flex-grow">
            <div className='flex flex-row mt-4 mb-8 items-center'>
                <div className="text-3xl font-bold text-brand mr-10">Reports By Type Within Each Ward</div>
                {/* <div className={'cursor-pointer text-brand mr-4' + (period === 'monthly' ? " font-bold" : "")} onClick={() => setPeriod('monthly')}>Monthly</div>
                <div className={'cursor-pointer text-brand' + (period === 'daily' ? " font-bold" : "")} onClick={() => setPeriod('daily')}>Daily</div> */}
            </div>
                <div style={{height: '100%', width: dataLen > 50 ? '3500px' : '100%'}}>
                    { data  ? <ResponsiveBar
                        data={data}
                        theme={theme}
                        indexBy="ward"
                        keys={types}
                        margin={{ top: 50, right: 240, bottom: 125, left: 60 }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 45,
                            legendPosition: 'middle',
                        }}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendOffset: -40
                        }}
                        enableGridX={false}
                        enableGridY={true}
                        enableLabel={false}
                        offsetType="none"
                        colors={{ scheme: 'category10' }}
                        fillOpacity={0.85}
                        borderColor={{ theme: 'background' }}
                        dotSize={8}
                        dotColor={{ from: 'color' }}
                        dotBorderWidth={2}
                        dotBorderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.7
                                ]
                            ]
                        }}
                        legends={[
                            {
                                anchor: 'right',
                                direction: 'column',
                                translateX: 20,
                                itemWidth: 0,
                                itemHeight: 30,
                                symbolSize: 12,
                                symbolShape: 'circle'
                            }
                        ]}
                    /> : <div className="w-full flex flex-row justify-center items-center mb-6 mt-2">
                        <SpinnerCircular size={50} color="#003399" secondaryColor="white" />
                    </div> }
                </div>
        </div>
    );
}

export default ReportsByTypeByWard;