import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import Sidebar from "../../components/Sidebar/Sidebar";
import ReportsByDay from "./ReportsByDay";
import ReportsByType from "./ReportsByType";
import ReportsByTypeByWard from "./ReportsByTypeByWard";
import ReportsOverTime from "./ReportsOverTime";
import ResponseTimes from "./ResponseTimes";
import './Reporting.scss';
import { SpinnerCircular } from "spinners-react";
import OutboundLinksThisMonth from "./OutboundLinksThisMonth";
import SearchHeader from "../../components/SearchHeader";
import ReportsByWard from "./ReportsByWard";
import ReportsByDistrict from "./ReportsByDistrict";
import ReportsByOutcome from "./ReportsByOutcome";
import APIFetch from "../../utilities/APIFetch";
import ReportsInWard from "./ReportsInWard";

function Reporting({ route }) {
    const { reportName } = useParams();
    const [adminStats, setAdminStats] = useState(null)

    useEffect(() => {
        APIFetch('GET', `reporting/stats`)
        .then(response => {
          if(response.ok) {
            setAdminStats(response.data);
          }
        })
    }, []);

    let reportComponent = null;

    switch(reportName) {
        case 'reportsbytype':
            reportComponent = <ReportsByType />
            break;
        case 'reportsbytypebyward':
            reportComponent = <ReportsByTypeByWard />
            break;
        case 'reportsovertime':
            reportComponent = <ReportsOverTime />
            break;
        case 'reportsbyday':
            reportComponent = <ReportsByDay />
            break;
        case 'responsetimes':
            reportComponent = <ResponseTimes />
            break;
        case 'outboundlinksthismonth':
            reportComponent = <OutboundLinksThisMonth />
            break;
        case 'reportsbyward':
            reportComponent = <ReportsByWard />
            break;
        case 'reportsinward':
            reportComponent = <ReportsInWard />
            break;
        case 'reportsbydistrict':
            reportComponent = <ReportsByDistrict />
            break;
        case 'reportsbyoutcome':
            reportComponent = <ReportsByOutcome />
            break;
        case 'home':
        default:
            reportComponent = adminStats === null ? <SpinnerCircular size={50} color="#003399" secondaryColor="white" /> :
                <div className="reporting p-4">
                    <div className="text-3xl mt-4 mb-8 font-bold text-brand">Quick Stats</div>
                    <div className="flex flex-grow">
                        <div className="stats-card stats-card-text">
                            <div className="text-xxlg">
                                {adminStats.reports}
                            </div>
                            <div className="stats-text">
                                Reports (last 24hrs)
                            </div>
                        </div>
                        <div className="stats-card stats-card-text">
                            <div className="text-xxlg">
                                {adminStats.activeSubmissions}
                            </div>
                            <div className="stats-text">
                                Open Submissions
                            </div>
                        </div>
                        <div className="stats-card stats-card-text">
                            <div className="text-xxlg">
                                {adminStats.closed}
                            </div>
                            <div className="stats-text">
                                Closed Today
                            </div>
                        </div>
                    </div>
                    <div className="stats-card flex flex-row">
                        <CircularProgress size={180} strokeWidth={20} progress={adminStats.percentageComplete ? adminStats.percentageComplete.toFixed(1) : 0} />
                        <div className="mt-4 mb-0 stats-text">
                            Responses within 48hrs
                        </div>
                    </div>
                </div>;
            break;
    }

    return (
        <div className="App">
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className={"flex flex-col p-4" + (reportName === 'home' ? "" : " flex-grow")}>
                    <SearchHeader />
                    {reportComponent}
                </div>
            </div>
        </div>
    );
}

export default Reporting;