function QuestionDetails({ question }) {
    return <div className="flex flex-col bg-white p-4 mb-4 rounded-lg text-black shadow">
        <div className="text-lg text-brand font-bold">Question Details</div>
        <div className="flex flex-row my-1">
            <div className="font-bold mr-2">Reference Number:</div>
            <div>{question.referenceNumber ? question.referenceNumber.toString().padStart(8, '0') : ''}</div>
        </div>
        <div className="flex flex-row my-1">
            <div className="font-bold mr-2">Submitted On:</div>
            <div>{new Date(question.createdAt).toLocaleDateString('en-GB')} {new Date(question.createdAt).toLocaleTimeString('en-GB')}</div>
        </div>
        {question.incidentNumber ? <div className="flex flex-row my-1">
            <div className="font-bold mr-2">Incident Reference Number:</div>
            <div>{question.incidentNumber}</div>
        </div> : null }
        <div className="flex flex-col my-1">
            <div className="font-bold mr-2">Question:</div>
            <div>{question.description}</div>
        </div>
    </div>;
}

export default QuestionDetails;