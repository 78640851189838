import { useLocation } from "react-router-dom";
import FeedList from "../../components/FeedList";
import Sidebar from "../../components/Sidebar/Sidebar";

function Dashboard() {
  const location = useLocation();

  return (
    <div className="App">
      <div className="flex flex-row w-full h-full overflow-y-hidden">
        <Sidebar />
        <div className="flex flex-col p-4 flex-grow">
          <FeedList initialSearch={location && location.state ? location.state.search : null} initialState={location && location.state ? location.state.state : null}/>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;