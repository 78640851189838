import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function SidebarItem({ uri, title, subItems, isChild }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(location.pathname === uri);
  const active = location.pathname === uri || (subItems && subItems.filter(i => i.uri === location.pathname).length > 0);

  if(subItems && subItems.length > 0 && (open || subItems.some(s => s.uri === location.pathname))) {
      return (
        <div>
            <div className={"flex flex-row items-center font-bold text-sm p-4 rounded-t-lg hover:bg-brand-active cursor-pointer" + (active ? " bg-brand-active" : "")} onClick={() => { navigate(uri) }}>
                {title || 'Ooops'}
                <span className="ml-auto flex">
                    <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} className="cursor-pointer" onClick={() => setOpen(!open)}/>
                </span>
            </div>
            {subItems.map((child, i) => <SidebarItem isChild={true} {...child} key={i} />)}
        </div>
      );
  }

  if(isChild) {
    return (
        <div className={"font-bold text-sm p-4 bg-white cursor-pointer last-of-type:rounded-b-lg" + (active ? " text-brand" : " text-brand-active")} onClick={() => { navigate(uri) }}>
            {title || 'Ooops'}
        </div>
      );
  }

  return (
    <div className={"font-bold text-sm p-4 rounded-lg hover:bg-brand-active cursor-pointer" + (active ? " bg-brand-active" : "")} onClick={() => { navigate(uri) }}>
        {title || 'Ooops'}
    </div>
  );
}

export default SidebarItem;