import { ResponsiveBar } from '@nivo/bar';
import { useEffect, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import APIFetch from '../../utilities/APIFetch';

const MONTH_LOOKUP = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
}


function ReportsOverTime() {
    const [period, setPeriod] = useState('monthly');
    const [data, setData] = useState(null);

    // const data = {
    //     daily: [
    //     {
    //         date: '03/03/2022',
    //         "Anti Social Behaviour": 105,
    //         "Suspicious Behaviour": 78,
    //         "Drugs": 24,
    //         "Hate Incident": 5,
    //         "Vehicle - Speeding": 7,
    //         "Vehicle - Anti Social Behaviour": 2
    //     },
    //     {
    //         date: '04/03/2022',
    //         "Anti Social Behaviour": 154,
    //         "Suspicious Behaviour": 100,
    //         "Drugs": 32,
    //         "Hate Incident": 7,
    //         "Vehicle - Speeding": 21,
    //         "Vehicle - Anti Social Behaviour": 6
    //     },
    //     {
    //         date: '05/03/2022',
    //         "Anti Social Behaviour": 112,
    //         "Suspicious Behaviour": 56,
    //         "Drugs": 21,
    //         "Hate Incident": 12,
    //         "Vehicle - Speeding": 15,
    //         "Vehicle - Anti Social Behaviour": 7
    //     },
    //     {
    //         date: '06/03/2022',
    //         "Anti Social Behaviour": 123,
    //         "Suspicious Behaviour": 98,
    //         "Drugs": 34,
    //         "Hate Incident": 12,
    //         "Vehicle - Speeding": 6,
    //         "Vehicle - Anti Social Behaviour": 3
    //     },
    //     {
    //         date: '07/03/2022',
    //         "Anti Social Behaviour": 198,
    //         "Suspicious Behaviour": 102,
    //         "Drugs": 37,
    //         "Hate Incident": 15,
    //         "Vehicle - Speeding": 24,
    //         "Vehicle - Anti Social Behaviour": 12
    //     },
    //     {
    //         date: '08/03/2022',
    //         "Anti Social Behaviour": 215,
    //         "Suspicious Behaviour": 121,
    //         "Drugs": 48,
    //         "Hate Incident": 19,
    //         "Vehicle - Speeding": 23,
    //         "Vehicle - Anti Social Behaviour": 14
    //     },
    //     {
    //         date: '09/03/2022',
    //         "Anti Social Behaviour": 105,
    //         "Suspicious Behaviour": 78,
    //         "Drugs": 24,
    //         "Hate Incident": 5,
    //         "Vehicle - Speeding": 7,
    //         "Vehicle - Anti Social Behaviour": 2
    //     },
    //     {
    //         date: '10/03/2022',
    //         "Anti Social Behaviour": 154,
    //         "Suspicious Behaviour": 100,
    //         "Drugs": 32,
    //         "Hate Incident": 7,
    //         "Vehicle - Speeding": 21,
    //         "Vehicle - Anti Social Behaviour": 6
    //     },
    //     {
    //         date: '11/03/2022',
    //         "Anti Social Behaviour": 112,
    //         "Suspicious Behaviour": 56,
    //         "Drugs": 21,
    //         "Hate Incident": 12,
    //         "Vehicle - Speeding": 15,
    //         "Vehicle - Anti Social Behaviour": 6
    //     }],
    //     monthly: [
    //         {
    //             date: 'Jan',
    //             "Anti Social Behaviour": 215,
    //             "Suspicious Behaviour": 121,
    //             "Drugs": 48,
    //             "Hate Incident": 19,
    //             "Vehicle - Speeding": 23,
    //             "Vehicle - Anti Social Behaviour": 14
    //         },
    //         {
    //             date: 'Feb',
    //             "Anti Social Behaviour": 198,
    //             "Suspicious Behaviour": 102,
    //             "Drugs": 37,
    //             "Hate Incident": 15,
    //             "Vehicle - Speeding": 24,
    //             "Vehicle - Anti Social Behaviour": 12
    //         },
    //         {
    //             date: 'Mar',
    //             "Anti Social Behaviour": 123,
    //             "Suspicious Behaviour": 98,
    //             "Drugs": 34,
    //             "Hate Incident": 12,
    //             "Vehicle - Speeding": 6,
    //             "Vehicle - Anti Social Behaviour": 3
    //         },
    //         {
    //             date: 'Apr',
    //             "Anti Social Behaviour": 112,
    //             "Suspicious Behaviour": 56,
    //             "Drugs": 21,
    //             "Hate Incident": 12,
    //             "Vehicle - Speeding": 15,
    //             "Vehicle - Anti Social Behaviour": 7
    //         },
    //         {
    //             date: 'May',
    //             "Anti Social Behaviour": 154,
    //             "Suspicious Behaviour": 100,
    //             "Drugs": 32,
    //             "Hate Incident": 7,
    //             "Vehicle - Speeding": 21,
    //             "Vehicle - Anti Social Behaviour": 6
    //         },
    //         {
    //             date: 'Jun',
    //             "Anti Social Behaviour": 105,
    //             "Suspicious Behaviour": 78,
    //             "Drugs": 24,
    //             "Hate Incident": 5,
    //             "Vehicle - Speeding": 7,
    //             "Vehicle - Anti Social Behaviour": 2
    //         },
    //         {
    //             date: 'Jul',
    //             "Anti Social Behaviour": 112,
    //             "Suspicious Behaviour": 56,
    //             "Drugs": 21,
    //             "Hate Incident": 12,
    //             "Vehicle - Speeding": 15,
    //             "Vehicle - Anti Social Behaviour": 7
    //         },
    //         {
    //             date: 'Aug',
    //             "Anti Social Behaviour": 215,
    //             "Suspicious Behaviour": 121,
    //             "Drugs": 48,
    //             "Hate Incident": 19,
    //             "Vehicle - Speeding": 23,
    //             "Vehicle - Anti Social Behaviour": 14
    //         },
    //         {
    //             date: 'Sep',
    //             "Anti Social Behaviour": 154,
    //             "Suspicious Behaviour": 100,
    //             "Drugs": 32,
    //             "Hate Incident": 7,
    //             "Vehicle - Speeding": 21,
    //             "Vehicle - Anti Social Behaviour": 6
    //         },
    //         {
    //             date: 'Oct',
    //             "Anti Social Behaviour": 198,
    //             "Suspicious Behaviour": 102,
    //             "Drugs": 37,
    //             "Hate Incident": 15,
    //             "Vehicle - Speeding": 24,
    //             "Vehicle - Anti Social Behaviour": 12
    //         },
    //         {
    //             date: 'Nov',
    //             "Anti Social Behaviour": 123,
    //             "Suspicious Behaviour": 98,
    //             "Drugs": 34,
    //             "Hate Incident": 12,
    //             "Vehicle - Speeding": 6,
    //             "Vehicle - Anti Social Behaviour": 3
    //         },
    //         {
    //             date: 'Dec',
    //             "Anti Social Behaviour": 105,
    //             "Suspicious Behaviour": 78,
    //             "Drugs": 24,
    //             "Hate Incident": 5,
    //             "Vehicle - Speeding": 7,
    //             "Vehicle - Anti Social Behaviour": 2
    //         }
    //     ]
    // };

    useEffect(() => {
        // Go back to start of first month
        var fromDate = new Date();
        fromDate.setFullYear(fromDate.getFullYear() - 1);
        fromDate.setDate(1);
        fromDate.setHours(0, 0, 0, 0);

        // Go back to start of month
        var toDate = new Date();
        toDate.setDate(1);
        toDate.setHours(0, 0, 0, 0);

        APIFetch('POST', `reporting/reportbreakdown`, {
            from: fromDate,
            to: toDate,
            period: 'monthly'
        })
        .then(response => {
          if(response.ok) {
            var processedData = [];
            response.data.forEach(r => {
                var newEntry = {
                    date: MONTH_LOOKUP[r.month] + ' ' + r.year.toString().substring(2),
                    "Anti Social Behaviour": r.asb,
                    "Suspicious Behaviour": r.suspiciousBehaviour,
                    "Drugs": r.drugs,
                    "Hate Incident": r.hateIncident,
                    "Vehicle - Speeding": r.vehicleSpeeding,
                    "Vehicle - Anti Social Behaviour": r.vehicleASB
                };

                if(r.other) {
                    newEntry["Other"] = r.other;
                }

                processedData.push(newEntry);
            });

            setData({ monthly: processedData });
          }
        })
    }, []);

    const theme = {
        textColor: '#2623FF',
        fontSize: '12px',
        background:'rgb(146, 177, 251, 0.2)',
        grid: {
            line: {
                stroke: '#2623FF'
            }
        },
        axis: {
            ticks: {
                text: {
                    fontWeight: 'bold'
                }
            }
        },
        legends: {
            text: {
                fontWeight: 'bold'
            }
        },
        tooltip: {
            container: {
                color: '#000000'
            }
        }
    };

    return (
        <div className="flex flex-col p-4 flex-grow">
            <div className='flex flex-row mt-4 mb-8 items-center'>
                <div className="text-3xl font-bold text-brand mr-10">Report Breakdown</div>
                <div className={'cursor-pointer text-brand mr-4' + (period === 'monthly' ? " font-bold" : "")} onClick={() => setPeriod('monthly')}>Monthly</div>
                <div className={'cursor-pointer text-brand' + (period === 'daily' ? " font-bold" : "")} onClick={() => setPeriod('daily')}>Daily</div>
            </div>
            { data && data[period] ? <ResponsiveBar
                data={data[period]}
                theme={theme}
                indexBy="date"
                keys={[
                    "Anti Social Behaviour",
                    "Suspicious Behaviour",
                    "Drugs",
                    "Hate Incident",
                    "Vehicle - Speeding",
                    "Vehicle - Anti Social Behaviour"
                ]}
                margin={{ top: 50, right: 240, bottom: 50, left: 60 }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: period === "monthly" ? 'Month' : 'Date',
                    legendOffset: period === "monthly" ? 80 : 55,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: -40
                }}
                enableGridX={false}
                enableGridY={true}
                enableLabel={false}
                offsetType="none"
                colors={{ scheme: 'category10' }}
                fillOpacity={0.85}
                borderColor={{ theme: 'background' }}
                dotSize={8}
                dotColor={{ from: 'color' }}
                dotBorderWidth={2}
                dotBorderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.7
                        ]
                    ]
                }}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        translateX: 20,
                        itemWidth: 0,
                        itemHeight: 30,
                        symbolSize: 12,
                        symbolShape: 'circle'
                    }
                ]}
            /> : <div className="w-full flex flex-row justify-center items-center mb-6 mt-2">
                <SpinnerCircular size={50} color="#003399" secondaryColor="white" />
            </div> }
        </div>
    );
}

export default ReportsOverTime;