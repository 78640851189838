import { StaffStore } from "../stores/StaffStore";
import { graphRequest } from "../authConfig";
import { msalInstance } from "../index";
import History from "./History";
var pendingProfiles = {};

export const getProfile = async (userId) => {
    // Return existing data if available
    if(StaffStore.currentState[userId]) {
        return Promise.resolve({ ok: true, status: 200, data: StaffStore.currentState[userId] })
    }

    // Prevent multiple requests for the same profile
    if(pendingProfiles[userId]) {
        return pendingProfiles[userId];
    }

    const account = msalInstance.getActiveAccount();
    
    if (!account) {
        History.navigate('/');
        return Promise.reject({ ok: false, status: null, data: null });
    }

    const response = await msalInstance.acquireTokenSilent({
        ...graphRequest,
        account: account
    });

    var token = response.accessToken;

    if(!token) {
        History.navigate('/');
        return Promise.reject({ ok: false, status: null, data: null });
    }

    pendingProfiles[userId] = fetch("https://graph.microsoft.com/v1.0/users/" + userId + "?&$select=displayName,givenName,surname", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
    .then((response) => {
        if(response.ok) {
            return response.json()
            .then(result => {
                StaffStore.update(s => { s[userId] = result });
                delete pendingProfiles[userId];
                return { ok: response.ok, status: response.status, data: result };
            })
            .catch(e => {
                return e;
            });
        } else {
            delete pendingProfiles[userId];
            StaffStore.update(s => { s[userId] = { id: userId } });
            return { ok: response.ok, status: response.status, data: response };
        }
    })
    .catch((e) => {
        return e;
    });

    return pendingProfiles[userId];
}