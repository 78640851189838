import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OrderBy = ({ active, direction, onChange }) => {
    return <div className="flex flex-col items-center justify-center px-2 h-full ml-2">
        <FontAwesomeIcon className={active && direction === "asc" ? "cursor-pointer" : "cursor-pointer text-brand-active"} icon={faChevronUp} onClick={() => { onChange("asc") }}/>
        <FontAwesomeIcon className={active && direction === "desc" ? "cursor-pointer" : "cursor-pointer text-brand-active"} icon={faChevronDown} onClick={() => { onChange("desc") }}/>
    </div>;
}

export default OrderBy;