import { useEffect, useState, useCallback, useRef } from "react";
import { SpinnerCircular } from "spinners-react";
import APIFetch from "../utilities/APIFetch";
import FeedItem from "./FeedItem";
import Modal from "./Modal";
import OrderBy from "./OrderBy";
import SearchHeader from "./SearchHeader";

function FeedList({ userId, count, initialSearch, initialState, searchProps }) {
    const [feed, setFeed] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fullyLoaded, setFullyLoaded] = useState(false);
    const [submittedSearch, setSubmittedSearch] = useState("");
    const [stateFilter, setStateFilter] = useState(1);
    const [error, setError] = useState(null);
    const [order, setOrder] = useState({ field: 'referenceNumber', direction: 'desc' });
    const loader = useRef(null);

    useEffect(() => {
      setSubmittedSearch(initialSearch);
      setStateFilter(initialState || 0);
      applySearch({ valueOverride: initialSearch, stateOverride: initialState || null});
    }, [initialSearch, initialState]);

    const applySearch = useCallback(({ scroll = false, valueOverride = null, stateOverride = null, orderOverride = null}) => {
      setLoading(true);
  
      let query = {
        count: count || 20,
        orderBy: orderOverride || order
      };

      if(valueOverride || submittedSearch) {
        query.textSearch = valueOverride !==  null ? valueOverride : submittedSearch;
      }
  
      if(scroll) {
        query.lastDate = feed[feed.length - 1].createdAt;
      }

      if(userId) {
        query.userId = userId;
      }

      if((stateOverride !== null && stateOverride === 1) || (stateOverride === null && stateFilter === 1)) {
        query.openOnly = true;
      } else if((stateOverride !== null && stateOverride === 2) || (stateOverride === null && stateFilter === 2)) {
        query.closedOnly = true;
      } else if((stateOverride !== null && stateOverride === 3) || (stateOverride === null && stateFilter === 3)) {
        query.newOnly = true;
      }
  
      APIFetch("POST", "feed", query)
      .then(result => {
          if(result.ok) {
            if(result.data.length < query.count) {
              setFullyLoaded(true);
            }

            if(scroll) {
              setFeed([...feed].concat(result.data))
            } else {
              setFeed(result.data);
            }
            setLoading(false);
          } else {
            setError(true);
          }
      });
    }, [count, feed, submittedSearch, userId]);
  
    const handleObserver = useCallback((entries) => {
      const target = entries[0];
      if (!loading  && !fullyLoaded && target.isIntersecting) {
        applySearch({ scroll: true });
      }
    }, [fullyLoaded, loading, applySearch]);
  
    useEffect(() => {
      const option = {
        root: null,
        rootMargin: "20px",
        threshold: 0
      };
      const observer = new IntersectionObserver(handleObserver, option);
      const current = loader.current;

      if (current) observer.observe(current);
  
      return () => {
        if (current) observer.unobserve(current);
      }
    }, [fullyLoaded, loading, handleObserver]);

    const onOrderChange = (field, direction) => {
      setOrder({ field, direction });
      applySearch({ orderOverride: { field, direction } });
    }

    return [
        <SearchHeader initialValue={initialSearch}
          initialState={initialState}
          onSubmit={({searchValue, state}) => { 
            setSubmittedSearch(searchValue);
            setStateFilter(state);
            applySearch({ valueOverride: searchValue, stateOverride: state });
          }} 
          onRefresh={() => {
            setFullyLoaded(false);
            applySearch({});
          }}
          {...searchProps} />,
        error ? <Modal onClose={() => setError(false)} heading={"Something went wrong"}>
            <div className="text-black mt-2 mb-8">An error occurred when attempting to load feed items.</div>
            <div className="flex flex-row mt-2">
                <div onClick={() => { setError(false); }} className='cursor-pointer font-bold bg-brand text-white rounded text-center py-3 ml-auto mr-auto mt-auto w-32'>OK</div>
            </div>
        </Modal> : null,
        <div key="header" className="text-brand flex flex-row p-4 m-2">
          <div className="text-brand basis-2/12 text-center font-bold flex flex-row justify-center items-center">Report Number<OrderBy active={order.field === 'referenceNumber'} direction={order.direction} onChange={(direction) => { onOrderChange('referenceNumber', direction) }}/></div>
          <div className="text-brand basis-3/12 text-center font-bold flex flex-row justify-center items-center">Category</div>
          <div className="text-brand basis-2/12 text-center font-bold flex flex-row justify-center items-center">User Name<OrderBy active={order.field === 'fullName'} direction={order.direction} onChange={(direction) => { onOrderChange('fullName', direction) }}/></div>
          <div className="text-brand basis-2/12 text-center font-bold flex flex-row justify-center items-center">Date<OrderBy active={order.field === 'createdAt'} direction={order.direction} onChange={(direction) => { onOrderChange('createdAt', direction) }}/></div>
          <div className="text-brand basis-2/12 text-center font-bold flex flex-row justify-center items-center">Status<OrderBy active={order.field === 'state'} direction={order.direction} onChange={(direction) => { onOrderChange('state', direction) }}/></div>
        </div>,
        <div className="flex flex-col overflow-y-scroll" key={"list"}>
            {feed && feed.map(f => {
              return <FeedItem key={f.referenceNumber} item={f} />
            })}
            <div ref={loader} className="w-full flex flex-row justify-center items-center mb-6 mt-2">
                <SpinnerCircular enabled={!fullyLoaded} size={50} color="#003399" secondaryColor="white" />
            </div>
        </div>
    ];
}

export default FeedList;