import appConfig from './appConfig.json';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].CLIENT_ID,
        authority: `https://login.microsoftonline.com/${appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].TENANT_ID}`,
        redirectUri: "/blank.html",
        postLogoutRedirectUri: "/",
        //navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [`api://${appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].CLIENT_ID}/api_access`]
};

export const graphRequest = {
    scopes: ["User.Read"]
};