const ToggleOptions = ({ options, value, onChange }) => {
    return <div className="flex flex-row text-brand">
        {options ? options.map((o, i) => {
            return <div key={o} className={"border border-l-0 first:border-l border-brand first:rounded-l-full last:rounded-r-full p-2 px-4 cursor-pointer" + (value === i ? " bg-brand text-white" : "")} onClick={() => { onChange(i) }}>
                {o}
            </div>
        }) : null }
    </div>
}

export default ToggleOptions;